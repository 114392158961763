<template>
  <div>
      <notifications></notifications>
    <!-- Header -->
    <!-- <div class="header py-7 py-lg-8 pt-lg-9">-->
      <!--<div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Welcome!</h1>
              <p class="text-lead text-white">Use these awesome forms to login or create new account in your project for
                free.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>-->
    <!-- </div>-->
    <!-- Page content -->
    <!--<div class="container mt--0 py-7 pb-5">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-header bg-soft-primary pb-4">
              <div class="row">
                  <div class="col-7"><div class="text-primary p-4">
                    <img src="https://coregem-imgs.s3.amazonaws.com/logo_header_removebg.png" alt="" class="align-left img-fluid">
      
                  <p class="mt-1 font-weight-bold">Sign in to continue to CoreGem.</p></div></div>
                  <div class="col-5"><img src="img/icons/common/profile-img.ba4e037e.png" alt="" class="img-fluid"></div>
              </div>
            </div>
            <div class="card-body px-lg-4 py-lg-4">
              <div class="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div>-->
             <!-- <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <b-alert
      variant="danger"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert=false"
    >
     {{errors}}
    </b-alert>
                    <base-input label="Bank Code"
                              class="mb-2"
                              name="Bank Code"
                              :rules="{required: true}"
                              prepend-icon="fa fa-university"
                              placeholder="Bank Code"
                              v-model="model.bankCode">
                  </base-input>
                  <base-input label="Username"
                              class="mb-2"
                              name="Username"
                              :rules="{required: true}"
                              prepend-icon="ni ni-circle-08"
                              placeholder="Username"
                              v-model="model.username">
                  </base-input>

                  <base-input label="Password"
                              class="mb-2"
                              name="Password"
                              :rules="{required: true}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Password"
                              v-model="model.password">
                  </base-input>
                  -->

                  <!--<base-checkbox v-model="model.rememberMe">Remember me</base-checkbox>-->
                  <!--<div>
                      <base-button @click="notifyVue('danger')" icon class="btn-facebook btn-icon">
               <span class="spinner-border"></span>
                <span class="btn-inner--text">Facebook</span>
              </base-button>
                  </div>-->
                 <!-- <div class="text-right">
                     <base-button icon type="primary" native-type="submit" :class="{disabled:loading}" class="my-4">
                <span class="btn-inner--text">Sign in</span>
                <span :class="{'spinner-border': loading}"></span>
              </base-button>
             -->
                   <!-- <base-button 
                    id="submitBtn"
                   type="primary" native-type="submit" class="my-4">Sign in</base-button>-->
                 <!-- </div>
                </form>
              </validation-observer>
               
            </div>
          </div>
          <div class="mt-4">
            <div class="text-center"><p>© 2020 CoreGem.</p></div>
          </div>
        </div>
      </div>
    </div>
  </div>-->
  <!-- Main Content -->
	<div class="container-fluid">
		<div class="row main-content bg-success text-center">
			<div class="col-md-4 text-center company__info">
				<img src="https://coregem-imgs.s3.amazonaws.com/logo_header_removebg.png" alt="" class="align-left img-fluid">
				<h4 class="company_title">Core Banking Application</h4>
			</div>
			<div class="col-md-8 col-xs-12 col-sm-12 login_form ">
				<div class="container-fluid">
					<div class="row">
						<h2 class="col-10 mt-2 ml-2">Log In</h2>
					</div>
					
						<form control="" @submit.prevent="onSubmit" >
            <div class="row">
								<input type="text" required v-model="model.bankCode" name="BankCode" id="bankCode" class="form__input" placeholder="BankCode">
							</div>
							<div class="row">
								<input type="text" required v-model="model.username" name="username" id="username" class="form__input" placeholder="Username">
							</div>
							<div class="row">
								<!--<i class="fa fa-lock icon"></i>-->
								<input type="password" required v-model="model.password" name="password" id="password" class="form__input" placeholder="Password">
							</div>
						<div class="row mb-3">
              <base-button block type="success"  native-type="submit" outline :disabled="loading"  class="btn col-12">Submit <span :class="{'spinner-border': loading}"></span></base-button>

								<!--<input type="submit" value="Submit" :disabled="loading" class="btn col-12"> <span :class="{'spinner-border': loading}"></span>-->
							</div>
						</form>
					
				</div>
			</div>
		</div>
	</div>
	<!-- Footer -->
	<div class="container-fluid text-center footer">
		 <div class="text-center"><p>© 2020 CoreGem.</p></div>
      
	</div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
 export default {
    components:{
        //BaseHeader
    },
    data() {
      return {
        model: {
          username: '',
          password: '',
         bankCode:'',
          rememberMe: false
        },
        notifications: {
          topCenter: false
        },
        //disableBtn:false,
        //spinner:false,
        showDismissibleAlert:false

      };
    },
    methods: {
      onSubmit() {
          this.$store.dispatch("logon", {
              username: this.model.username,
                userBankCode: this.model.bankCode,
    userPassword: this.model.password,
    source:"WEB",
    userCountryCode: "234"

          } , { root: false }); 
     // this.disableBtn = true;
    //this.spinner = true;
    //this.showDismissibleAlert=true;
      // dummy delay
     /* setTimeout(() => { 
        if(this.model.username==='123456'){
        this.disableBtn = !true;
    this.spinner = !true;
    }
      }, 20000);
*/
       // this.$router.push({ name: "Alternative" });
        
      },
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      }
    },
    computed: {
    ...mapState({errors: state => state.auth.errors,loading: state => state.auth.loading,success: state => state.auth.success}),
    ...mapActions(["logon"]),
    ...mapGetters([""]),
  },
  watch: {
    errors(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('danger',newValue);
        //this.showDismissibleAlert=true;
      }
    },
    success(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('success',` Login ${newValue}ful`);
        //this.showDismissibleAlert=true;
      }
    },
  },
  }
</script>
><style lang="css" scoped>
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
.text-primary {
    color: #212529!important;
}

.img-fluid, .img-thumbnail {
    max-width: 100%;
    height: auto;
}
img, svg {
    vertical-align: middle;
}
img {
    border-style: none;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: .8rem !important;
}
.bg-soft-primary {
    background-color: rgba(124, 124, 128, 0.25) !important;/* rgba(85,110,230,.25)!important;*/
}



.main-content{
	width: 50%;
	border-radius: 20px;
	box-shadow: 0 5px 5px rgba(0,0,0,.4);
	margin: 5em auto;
	display: flex;
}
.company__info{
	background-color: #e9ecef;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #fff;
}
.fa-android{
	font-size:3em;
}
@media screen and (max-width: 640px) {
	.main-content{width: 90%;}
	.company__info{
		display: none;
	}
	.login_form{
		border-top-left-radius:20px;
		border-bottom-left-radius:20px;
	}
}
@media screen and (min-width: 642px) and (max-width:800px){
	.main-content{width: 70%;}
}
.row > h2{
	color:#008080;
}
.login_form{
	background-color: #fff;
	border-top-right-radius:20px;
	border-bottom-right-radius:20px;
	border-top:1px solid #ccc;
	border-right:1px solid #ccc;
}
form{
	padding: 0 2em;
}
.form__input{
	width: 100%;
	border:0px solid transparent;
	border-radius: 0;
	border-bottom: 1px solid #aaa;
	padding: 1em .5em .5em;
	padding-left: 0em;
	outline:none;
	margin:1.5em auto;
	transition: all .5s ease;
}
.form__input:focus{
	border-bottom-color: #008080;
	box-shadow: 0 0 5px rgba(0,80,80,.4); 
	border-radius: 4px;
}
.btn{
	transition: all .5s ease;
	width: 70%;
	border-radius: 30px;
	color:#008080;
	font-weight: 600;
	background-color: #fff;
	border: 1px solid #008080;
	margin-top: 1.5em;
	margin-bottom: 1em;
}
.btn:hover, .btn:focus{
	background-color: #008080;
	color:#fff;
}


.input-container {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

/* Style the form icons */
.icon {
  padding: 10px;
  background: dodgerblue;
  color: white;
  min-width: 50px;
  text-align: center;
}

</style>
